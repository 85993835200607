<template>
  <v-container>
    <v-row class="text-center">

    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HelloWorld',
  }
</script>
