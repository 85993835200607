<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col
                cols="12"
                sm="4"
                md="4"
            >
              <v-switch
                  v-model="ex11"
                  label="Использовать кавычки для экранирования значений"
                  color="success"
                  value="success"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-row class="text-center">
      <v-col>
      <v-textarea
          label="Исходные данные"
          prepend-icon="mdi-vuetify"
          variant="outlined"
          v-model="listMessage"
          rows="15"
          row-height="25"
          shaped
      ></v-textarea>
      </v-col>
      <v-col>
        <v-textarea
            label="Результат"
            prepend-icon="mdi-vuetify"
            variant="outlined"
            readonly
            v-model="stringToList"
            rows="15"
            row-height="25"
            shaped
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DataToList",
  data() {
    return {
      ex11: 'success',
      listMessage: ''
    }
  },
  computed: {
    stringToList() {
      // console.log(typeof this.listMessage)
      const newArray = []
      const arrayList = this.listMessage.split('\n')
      const ex=this.ex11
      let finalArray
      arrayList.forEach(function (item) {
        console.log(ex)
        if (ex === 'success' && item.length > 0) {
          let arrItem = "'" + item + "'"
          newArray.push(arrItem)
        } else if (ex !== 'success' && item.length > 0) {
          newArray.push(item)
        }

      });
      if (ex === 'success') {
        finalArray = newArray.toString().replaceAll(',', ', ').replace(", ''", '').replace(", $", "")
      } else if (ex !== 'success') {
        finalArray = newArray.toString().replaceAll(',', ', ').replace(", $", "")
      }

      return finalArray
    }
  }
}
</script>

<style scoped>

</style>