<template>
  <div class="datatolist">
    <h1>Конвертер столбца в строку</h1>
    <DataToList/>
  </div>
</template>

<script>
// @ is an alias to /src
import DataToList from '@/components/DataToList.vue'

export default {
  name: 'DataToListView',
  components: {
    DataToList
  }
}
</script>

<style scoped>

</style>